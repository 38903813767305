import { getApiUrl } from "api/config";
import { RemoteDataGetRequest } from "api/remoteData";

const baseUrl = getApiUrl();

export const getFeatureFlags = (): RemoteDataGetRequest<
  Map<string, Map<string, any>>
> => ({
  url: `${baseUrl}/features`,
});
