/* eslint-disable react-hooks/exhaustive-deps */
import { getFeatureFlags } from "api/getFeatureFlags";
import { useRemoteData } from "api/remoteData";
import React, { useEffect, useState } from "react";

type Attribute = {
  [key: string]: number | string | number[] | string[] | boolean;
};

type FeatureFlag = {
  name: string;
  enabled: boolean;
  attributes: Attribute[];
};

const FlagContext = React.createContext<FeatureFlag[]>([]);

export function FeatureFlagProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlag[]>([]);

  const { remoteData } = useRemoteData<FeatureFlag[]>(getFeatureFlags());

  useEffect(() => {
    if (remoteData.status === "Success") {
      setFeatureFlags(remoteData.data);
    }
  }, [remoteData.status]);

  return (
    <FlagContext.Provider value={featureFlags}>{children}</FlagContext.Provider>
  );
}

export function useFeatureFlag(name: string): FeatureFlag {
  const flags = React.useContext(FlagContext);
  return (
    (flags.length ? flags.find((flag) => flag.name === name) : null) ?? {
      name,
      enabled: false,
      attributes: [],
    }
  );
}
